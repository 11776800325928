<template>
  <div class="geplante-bewegungen mb-12 border-b-2 border-gray-300 p-6">
    <section class="gp-bewegungen">
      <div class="main-action p-2 gap-2 text-sm text-secondary rounded mt-5 md:flex justify-between">
        <div class="add">
          <button
            type="button"
            @click="openModal({}, 'new')"
            title="neue Bewegungen erstellen"
            class="shadow-lg flex items-center text-lg border border-secondary py-1 px-2 rounded-lg font-light hover:text-white hover:bg-secondary hover:border-primary"
          >
            <span>{{ $t("new Account") }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </button>
        </div>
      </div>

      <div class="gp-bewegungen-top-row">
        <div class="text-xs justify-between text-primary mb-4 mt-4 rounded border-2 border-secondary px-5 py-2 grid grid-cols-2">
          <div class="gp-bew-pers hidden md:block">
            <span>{{ $t("name") }}</span>
          </div>
          <div class="gp-bew-betrag text-right">
            <span>{{ $t("amount in €") }}</span>
          </div>
          <!-- <div class="gp-bew-show text-right">
            <span>{{ $t("action") }}</span>
          </div> -->
        </div>
      </div>

      <div role="listbox" aria-labelledby="gp-bewegungen-label" id="gp-bewegungen">
        <div class="bewegungen-hit items-center" role="option" aria-selected="false" v-for="account in accountList.data" :key="account.id">
          <div @click="openModal(account, 'update')" class="bewegungen-inner items-center grid grid-cols-2 cursor-pointer">
            <div class="gp-bew-cat">
              <span>{{ $t(account.name) }}</span>
            </div>
            <div class="gp-bew-betrag text-right">
              <span>
                {{
                  parseFloat(account.credit).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                  })
                }}
              </span>
            </div>
            <!-- <div class="ef-bew-action flex justify-end">
              <div class="edit">
                <button>
                  <PencilAltIcon
                    class="h-6 w-6 text-fuchsia-600"
                    @click="openModal(account, 'update')"
                  />
                </button>
              </div>
              <div class="delete" v-if="!account.is_static">
                <button>
                  <TrashIcon
                    class="h-6 w-6 text-red-400"
                    @click="DeleteAccount(account.id)"
                  />
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <nav class="bg-white px-4 py-3 flex flex-col sm:flex-row items-center justify-between border-t border-gray-200 sm:px-6 print-display" aria-label="Pagination">
        <div v-if="accountList.meta" class="flex flex-col items-center">
          <p class="text-sm text-gray-700">
            {{ $t("Showing") }}
            <span class="font-medium">{{ accountList.meta.from || 0 }}</span>
            {{ $t("to") }}
            <span class="font-medium">{{ accountList.meta.to || 0 }}</span>
            {{ $t("of") }}
            <span class="font-medium">{{ accountList.meta.total || 0 }}</span>
            {{ $t("results") }}
          </p>
          <div class="text-sm text-gray-700 ml-3 mt-2">
            {{ $t("show per page") }}
            <select @change="formatCountPagination" v-model="count" class="rounded-md border-gray-300">
              <option :selected="count == 10" value="10">10</option>
              <option :selected="count == 20" value="20">20</option>
              <option :selected="count == 30" value="30">30</option>
              <option :selected="count == 100" value="30">{{ $t("All") }}</option>
            </select>
          </div>
        </div>

        <div v-if="accountList.meta" class="flex-1 flex justify-between sm:justify-end mt-2 sm:mt-0">
          <button
            @click="firstPageMovementList"
            :class="
              accountList.meta.current_page == 1
                ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
            "
            class="mr-1 relative inline-flex items-center px-1 py-2"
          >
            <ChevronDoubleLeftIcon class="h-4 w-4" />
          </button>
          <button
            @click="prePageMovementList"
            :disabled="accountList.meta.current_page == 1"
            :class="
              accountList.meta.current_page == 1
                ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40'
                : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
            "
            class="relative inline-flex items-center px-4 py-2"
          >
            {{ $t("Previous") }}
          </button>
          <button
            @click="nextPageMovementList"
            :disabled="accountList.meta.current_page == accountList.meta.last_page"
            :class="
              accountList.meta.current_page == accountList.meta.last_page
                ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
            "
            class="ml-3 relative inline-flex items-center px-4 py-2"
          >
            {{ $t("Next") }}
          </button>
          <button
            @click="nextLastPageMovementList"
            :class="
              accountList.meta.current_page == accountList.meta.last_page
                ? 'border border-secondary text-sm font-medium rounded-md text-white bg-secondary cursor-not-allowed opacity-40	'
                : 'border border-secondary text-sm font-medium rounded-md text-white hover:text-secondary hover:bg-white bg-secondary'
            "
            class="ml-1 relative inline-flex items-center px-1 py-2"
          >
            <ChevronDoubleRightIcon class="h-4 w-4" />
          </button>
        </div>
      </nav>
    </section>
  </div>
  <modal :open="showModal" @close="showModal = false" :type="type" :data="planning" :mode="mode"></modal>
</template>

<script>
import Modal from "@/components/Modal";
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from "@heroicons/vue/outline";
export default {
  components: { Modal, ChevronDoubleRightIcon, ChevronDoubleLeftIcon },
  data() {
    return {
      showModal: false,
      type: null,
      planning: null,
      mode: null,
      count: 10,
      page: 1,
    };
  },
  data() {
    return {
      showModal: false,
      type: null,
      planning: null,
      mode: null,
      count: 10,
    };
  },
  computed: {
    accountList() {
      return this.$store.state.accounts.accountList || [];
    },
  },
  methods: {
    nextPageMovementList() {
      this.page = this.accountList.meta.current_page + 1;
      this.getAccountList();
    },
    nextLastPageMovementList() {
      this.page = this.accountList.meta.last_page;
      this.getAccountList();
    },
    prePageMovementList() {
      this.page = this.accountList.meta.current_page - 1;
      this.getAccountList();
    },
    firstPageMovementList() {
      this.page = 1;
      this.getAccountList();
    },
    formatCountPagination() {
      this.getAccountList();
    },
    openModal(planning, mode) {
      this.showModal = true;
      this.planning = planning;
      this.mode = mode;
      this.type = "AccountModal";
    },
    DeleteAccount(id) {
      this.$store.dispatch("accounts/DeleteAccount", id);
    },
    getAccountList() {
      this.$store.dispatch("accounts/getAccountList", { count: this.count, page: this.page });
    },
  },
  mounted() {
    this.$store.dispatch("accounts/getAccountList");
  },
};
</script>

<style></style>
